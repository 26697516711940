<template>
  <div class="iphone-x">
    <!-- <i>Speaker</i> -->
    <!-- <b>Camera</b> -->

    <div class="screen" style="overflow: hidden">
      <iframe
        ref="previewIframe"
        id="preview-iframe"
        :src="src"
        class="bg-white"
        frameborder="0"
        scrolling="yes"
      ></iframe>
    </div>
    <s
      v-show="loading"
      style="z-index: 9999 !important"
      class="h-100 w-100 bg-primary position-absolute top-0"
    >
      <div class="text-white text-center">
        <div>
          <h3 class="text-xl">Swandoola</h3>
        </div>
      </div>
    </s>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";

export default {
  props: ["src"],
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    // setTimeout(() => {
    const iframe = this.$refs.previewIframe;
    iframe.addEventListener("load", () => {
      this.loading = false;
    });
    // }, 5000);
  },
  components: {
    Loading,
  },
};
</script>

<style lang="scss">
.iphone-x {
  position: relative;
  // margin: 40px auto;
  height: 694px;
  width: 342px;
  background-color: #6c4a9e;
  background-image: linear-gradient(60deg, #6c4a9e 1%, #f00d76 100%);
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  z-index: 10 !important;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  // &:before {
  //   top: 0px;
  //   width: 56%;
  //   height: 30px;
  //   background-color: #1f1f1f;
  //   border-radius: 0px 0px 40px 40px;
  // }

  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
  }

  // speaker
  i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);

    &:after {
      content: "";
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }

  // time
  s {
    top: 50px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 100%;
    font-size: 70px;
    font-weight: 100;
    padding-top: 60px;
  }

  // action buttons
  span {
    bottom: 50px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    left: 30px;

    & + span {
      left: auto;
      right: 30px;
    }
  }
}

.theme--light.v-sheet {
  background-image: linear-gradient(60deg, rgba(84, 58, 183, 0.6) 0%, rgba(0, 172, 193, 0.6) 100%);
}

video {
  height: 700px;
  width: 350px;
  margin-top: -30px;
  margin-left: -10px;
}

#preview-iframe {
  height: 694px;
  /* width: 350px; */
  width: 100%;
  // transform: scale(0.75);
  // margin-top: -112px;
  // margin-left: -50px;
  // padding-bottom: 30px;
  z-index: 2 !important;
  border-radius: 40px;
}

#preview-iframe {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

#preview-iframe::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
